import React, { useEffect } from 'react'
import styles from './index.module.scss'

function ModalMask(props) {
  const { children, onClose } = props
  useEffect(() => {
    function touchMove(e) {
      e.preventDefault()
    }

    document.body.addEventListener('touchmove', touchMove, { passive: false })

    return () => {
      document.body.removeEventListener('touchmove', touchMove)
    }
  }, [])

  return (
    <div className={styles.mask} onClick={onClose}>
      { children }
    </div>
  )
}

export default ModalMask
