import axios from 'axios'
import app from '@/app'

const service = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? 'https://api.yaoxiebang.com.cn' : 'https://api.yaoxiebang.cn',
  timeout: 10 * 1000
})

service.interceptors.request.use(
  config => {
    let token = app._store.getState().user.token
    if(token) {
      config.headers.Authorization = token
    }

    return config
  }
)

service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

export default service