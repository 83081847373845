import request from '@/utils/request2'

/**
 * 获取聊天室ID
 * @param {*} data 
 */
export function getChatroomId(data){
  return request({
    url: '/v2/chat/getRoomId',
    method: 'post',
    data
  })
}

/**
 * 发送群发消息
 * @param {object} data
 * @param {0|1|4|16} data.type - 消息类型 0: 文本 1: 图片 4: 个人名片 16: 产品
 * @param {string} data.body - 内容
 * @param {'active_user'|'area'|'attention'} data.mass_type - 目标用户类型
 * @param {number|number[]} data.mass_value - 目标用户数据
 * @param {string} data.extra - 额外信息
 */
export function sendGroupMessage(data){
  return request({
    url: '/v2/chat/filterGroup',
    method: 'post',
    data
  })
}

/**
 * 获取群发消息列表
 * @param {object} params
 * @param {number} params.before - 消息id
 * @param {number} params.limit - 分页
 */
export function getGroupMessages(params){
  return request({
    url: '/v2/chat/massList',
    method: 'get',
    params
  })
}