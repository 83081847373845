import React from 'react'
import { router } from 'dva'
import Loadable from '@/router/loadable'
const { Route } = router

const VisitorDataHome = Loadable(() => import("./VisitorData"))
const VisitorDataOfProduct = Loadable(() => import('./VisitorDataOfProduct'))

export default function VisitorData() {
  return (
    <>
      <Route exact path='/visitor-data/:id' component={VisitorDataOfProduct} />
      <Route exact path='/visitor-data' component={VisitorDataHome} />
    </>
  )
}