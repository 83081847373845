import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Header from '@/components_v2/Header'
import styles from './index.module.scss'
import { goBack } from "@/utils/webViewUtils"
import ModalMask from "@/components_v2/Modal/Mask"
import SendWithTarget from "../components/SendWithTarget"
import { sendGroupMessage, getGroupMessages } from "@/services/message"
import { useOnceRequest, useStateCallback } from "@/hooks"
import { Toast } from "antd-mobile"
import ScrollView from "@/components_v2/ScrollView"

const operate = [
  { label: '发送文字', to: '/group-message/sendText' },
  { label: '发送图片', to: '/group-message/sendPicture' },
  { label: '发送产品', to: '/group-message/sendProduct' },
  { label: '发送主页', to: '' }
]

export default function MassMessagingList(props) {
  const [messages, setMessages] = useStateCallback([])
  const [getList, isPending] = useOnceRequest(getGroupMessages)
  const paging = useRef({ limit: 10, noData: false })
  const getMessages = useCallback(params => {
    if(paging.current.noData){
      return Promise.resolve([])
    }
    const { limit } = paging.current

    return getList({ limit, ...params }).then(res => {
      if(res.code !== 1000) {
        return []
      }

      if(res.data.length < limit){
        paging.current.noData = true
      }

      return res.data.reverse()
    })
  }, [messages])

  const getInitialData = useCallback(() => {
    getMessages().then(data => {
      setMessages(data, () => {
        const el = scrollRef.current
        const diff = el.scrollHeight - el.clientHeight
        if(diff > 0){
          el.scrollTop = diff
        }
      })
    })
  }, [getMessages])

  useEffect(() => {
    getInitialData()
  }, [])

  const scrollRef = useRef()
  const onScroll = useCallback(({ target }) => {
    const { scrollTop, scrollHeight } = target
    if(scrollTop === 0 && !isPending() && !paging.current.noData) {
      let params = {}
      if(messages[0]){
        params.before = messages[0].mass_id
      }

      getMessages(params).then(data => {
        setMessages([...data, ...messages], () => {
          const el = scrollRef.current
          el.scrollTop = el.scrollHeight - scrollHeight
        })
      })
    }
  }, [messages])

  const [visibleMask, setVisibleMask] = useState(false)
  const onClose = useCallback(() => {
    setVisibleMask(false)
  }, [])

  const stopPropagation = useCallback(e => e.stopPropagation(), [])

  const [visible, setVisible] = useState(false)
  const sendStart = useCallback(to => {
    if(to) {
      props.history.push(to)
    } else {
      setVisibleMask(false)
      setVisible(true)
    }
  }, [])

  const send = useCallback(({ type, value: val, title }) => ({
    promise: sendGroupMessage({
      type: 4,
      body: '0',
      mass_type: type,
      mass_value: val,
      extra: title
    }),
    success: res => {
      setVisible(false)
      getInitialData()
    }
  }), [getInitialData])

  return (
    <div className={styles.container}>
      <Header title="消息群发" onGoBack={goBack} />
      <ScrollView ref={scrollRef} className={styles.content} onScroll={onScroll}>
        <div className={styles.content}>
          <img src={require('@/images/groupMessage/banner.png')} alt="消息群发" className={`${styles['list-item']} ${styles.banner}`} />
          {
            messages.map(item => <ListItem key={item.mass_id} data={item} /> )
          }
        </div>
      </ScrollView>
      <CreateGroupMessage onClick={() => setVisibleMask(true)} />
      {
        visibleMask && (
          <ModalMask onClose={onClose}>
            <div className={styles.modal}>
              <div className={styles['modal_container-wrapper']}>
                <div className={styles.modal_container} onClick={stopPropagation}>
                  <img src={require('@/images/groupMessage/modal_banner.png')} alt="" className={styles.modal_banner} />
                  <div className={styles.modal_content}>
                    <div className={styles.modal_title}>选择群发内容</div>
                    <div className={styles.modal_btns}>
                      {
                        operate.map(({ label, to }, i) => (
                          <button key={i} className={styles.modal_btn} onClick={() => sendStart(to)}>{label}</button>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalMask>
        )
      }
      <SendWithTarget visible={visible} type="主页" onGoBack={() => setVisible(false)} onSend={send} />
    </div>
    // <div className={styles.container}>
    // </div>
  )
}

function ListItemTime({ value }) {

  const val = useMemo(() => {
    if(!value) return ''
    
    let [date, time] = value.split(' ')
    date = date.split('-')
    date = `${date[0]}年${date[1]}月${date[2]}日`
    return date + ' ' + time.slice(0, 5)
  }, [value])

  return (
    <div className={styles['list-item_time-wrapper']}>
      <div className={styles['list-item_time']}>{val}</div>
    </div>
  )
}

function ListItem(props) {
  const { data } = props

  const Childern = useMemo(() => {
    if(data.type === 0) {
      return <Text data={data} />
    } else if (data.type === 1) {
      return <Image data={data} />
    } else if (data.type === 4) {
      return <PersonalCard data={data} />
    } else if (data.type === 16) {
      return <Product data={data} />
    } else {
      return null
    }
  }, [data])

  return (
    <div className={styles['list-item']}>
      <ListItemTime value={data.create_time} />
      <div className={styles['list-item_card']}>
        <div className={styles['list-item_title']}>{data.extra}</div>
        <div className={styles['list-item_content']}>
          { Childern }
        </div>
        {/* <div className={styles['list-item_agent-send']}>再发一条</div> */}
      </div>
    </div>
  )
}

function Text({ data }) {
  return (
    <div className={styles['list-item_text']}>{data.body}</div>
  )
}

function Image({ data }) {
  return (
    <div className={styles['list-item_image-wrapper']}>
      <img className={styles['list-item_image']} src={data.body} alt="图片" />
    </div>
  )
}

function Product({ data }) {
  const { body = {} } = data
  const certificationData = useMemo(() => {
    const data = {...body.autit, ...body.user}
    return Object.keys(data).filter(key => data[key] === 2)
  }, [body])
  
  return (
    <div className={styles.product}>
      <img className={styles.product_image} src={body.picture} alt="产品图片" />
      <div className={styles.product_info}>
        <div className={`${styles.product_name} single-line_text`}>{body.productName}</div>
        <div className={`${styles.product_factory} single-line_text`}>{body.manufacturerName}</div>
        <div className={`${styles.product_advantage} single-line_text`}>{body.advantage}</div>
        <div>
          {
            body.keyword.map(item => (
              <span key={item} className={styles.product_tag}>{item}</span>
            ))
          }
        </div>
        <div className={styles.product_certifications}>
          <Certifications data={certificationData} />
        </div>
      </div>
    </div>
  )
}

function PersonalCard({ data }) {
  const { body = {} } = data
  const { product_num = 0, user = {} } = body
  return (
    <div className={styles.personal}>
      {/* <Certifications /> */}
      <div className={styles.personal_info}>
        <img className={styles.personal_img} src={user.avatar} alt="" />
        <div className={styles.personal_info_content}>
          <div className={styles.personal_company}>
            <div className={`${styles.personal_company_name} single-line_text`}>{user.username}</div>
            {/* <div className={styles.personal_company_view}>
              <div>查看主页</div>
              <img src={require('@/images/product/arrow_right_gray.png')} />
            </div> */}
          </div>
          <div className={styles.personal_product}>
            <span className={styles.personal_product_title}>产品</span>
            <span className={styles.personal_product_number}>{product_num}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

function CreateGroupMessage(props) {
  return (
    <div className={styles['create-group-message_wrapper']}>
      <button className={styles['create-group-message']} {...props}>新建群发</button>
    </div>
  )
}

function Certifications({ data = [] }) {
  const certificates = useMemo(() => {
    const statuses = [
      { key: 'company_status', title: '企业认证', theme: 'yellow' },
      { key: 'exclusive', title: '独家批文', theme: 'green' },
      { key: 'new_product', title: '新品招商', theme: 'green' },
      { key: 'insurance', title: '医保目录', theme: 'green' },
      { key: 'series', title: '系列产品', theme: 'green' },
      { key: 'factory_status', title: '厂家直招', theme: 'yellow' },
      { key: 'total_agent_status', title: '全国总代', theme: 'yellow' },
      { key: 'person_status', title: '个人认证', theme: 'blue' },
    ]
    
    return statuses.filter(({ key }) => data.includes(key)).slice(0, 2)
  }, [data])

  return (
    <div>
      {
        certificates.map(({ key, title, theme }) => (
          <Certification key={key} title={title} theme={theme} />
        ))
      }
    </div>
  )
}

function Certification({ title, theme }) {

  const icon = useMemo(() => {
    if(theme === 'blue'){
      return require('@/images/certification/certification_blue.png')
    } else if(theme === 'green'){
      return require('@/images/certification/certification_green.png')
    } else if(theme === 'yellow') {
      return require('@/images/certification/certification_yellow.png')
    }
  }, [theme])

  return (
    <div className={`${styles.certification} ${styles[`${theme}`]}`}>
      <img className={styles['certification-icon']} src={icon} alt='' />
      <div className={styles['certification-title']}>{title}</div>
    </div>
  )
}