
/**
 * 获取时间信息的数组 - [年, 月, 日, 时, 分, 秒, 毫秒]
 * @param {*} time
 */
export function getDateOfArray(time) {
  const date = time ? new Date(time) : new Date()
  
  return [
    date.getFullYear(), 
    date.getMonth() + 1, 
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  ]
}

/**
 * 获取随机字符串
 * @param {*} len 
 */
export function randomString(len) {
  len = len || 32
  let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'    /**** 默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1 ****/
  let maxPos = $chars.length
  let pwd = ''

  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }

  return pwd
}