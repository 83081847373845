import "./utils/request"
import "./index.styl"
import app from './app'
import VConsole from "vconsole"
require('promise.prototype.finally').shim()

if (process.env.NODE_ENV !== "production") {
  new VConsole()
}

app.model(require('@/models/user').default)

app.router(require('@/router').default)

app.start('#root')