import React, { memo, useMemo, useState, useEffect, useCallback, useRef, useImperativeHandle } from 'react'
import styles from './index.module.scss'
import Header from '@/components_v2/Header'
import selector from '@/hocs/selector'
import { connect, useSelector, useDispatch } from 'dva'
import { Toast } from 'antd-mobile'
import { Confirm } from '@/components_v2/Modal'
import VipModal from '../VipModal'
import VipRefreshModal from '../VipModal/Refresh'

function SendWithTarget({ onGoBack, visible = true, onSend, type }) {
  const targets = useMemo(() => [ActiveUsers, AgentAreas, FollowTypes], [])
  const [target, setTarget] = useState(0)
  const TargetComponent = useMemo(() => targets[target], [target])
  const targetRef = useRef()

  const send = useCallback(() => {
    const { value, title } = targetRef.current.getValue()
    if(
      ( target === 1 && !value[0])
      || ( target === 2 && !value[1])
    ){
      Toast.info('请选择转发目标', 2, null, false)
      return
    }

    setModalContent(`您将发送${type}给${title}人群，请确认无误后点击发送`)
    toggleModal(true)
  }, [target, type])

  const [modal, toggleModal] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const closeModal = useCallback(() => toggleModal(false), [])
  const vipModalRef = useRef()
  const [restMessageCount, setRestMessageCount] = useState(-1)
  const success = useRef(() => {})
  const onDetermine = useCallback(() => {
    const { value, title } = targetRef.current.getValue()

    if(onSend) {
      const { promise: send, success: callback } = onSend({ type: ['active_user', 'area', 'attention'][target], value, title: `发送人群：${title}` })
      typeof callback === 'function' && (success.current = callback)
      if(send.then && typeof send.then === 'function') {
        send.then(res => {
          if(res.code === 8888) {
            vipModalRef.current && vipModalRef.current.open(res.data.vip_level)
            return
          }
    
          if(res.code !== 1000) {
            Toast.info(res.msg, 2, null, false)
            return
          }

          setRestMessageCount(res.data.mass_num)
        })
      }
    }
  }, [onSend, target])

  const visibleRefresh = useMemo(() => restMessageCount >= 0,[restMessageCount])
  const closeRefreshModal = useCallback(() => success.current(), [])

  if(!visible) {
    return null
  }

  return (
    <div className={styles.container}>
      <Header title="消息转发" onGoBack={onGoBack} className={styles.header} />
      <div className={styles.tabs}>
        {
          targets.map((item, i) => (
            <div key={i} className={styles.tab_wrapper}>
              <div className={`${styles.tab} ${i === target ? styles.tab_active : ''}`} onClick={() => setTarget(i)}>{item.type}</div>
            </div>
          ))
        }
      </div>
      <div className={styles.content}>
        <TargetComponent ref={targetRef} />
      </div>
      <button className={styles['next-step']} onClick={send}>确认发送</button>
      <Confirm 
        visible={modal} 
        title="群发确认"
        content={modalContent}
        onCancel={closeModal}
        onDetermine={onDetermine}
      />
      <VipModal ref={vipModalRef} />
      {
        visibleRefresh && (
          <VipRefreshModal onDetermine={closeRefreshModal}>本日还可发送<span style={{color: '#FF7C34'}}> {restMessageCount} </span>次</VipRefreshModal>
        )
      }
    </div>
  )
}

export default connect(({ groupMessage }) => groupMessage)(SendWithTarget)

const ActiveUsers = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(1)

  const activeList = useMemo(() => [
    { title: '今日活跃', data: 1 },
    { title: '最近3日活跃', data: 3 },
    { title: '最近7日活跃', data: 7 },
    { title: '最近一个月活跃', data: 30 }
  ], [])

  const ActiveList = useMemo(() => activeList.map(({ title, data }) => (
    <ActiveUser key={data} title={title} selected={value === data} data={data} onChange={setValue} />
  )), [value])

  useImperativeHandle(ref, () => ({
    getValue: () => ({
      value: parseInt((Date.now() - (value * 1000 * 60 * 60 * 24)) / 1000),
      title: activeList.find(({ data }) => data === value).title
    })
  }))

  return (
    <div className={styles['active-users']}>
      { ActiveList }
    </div>
  )
})

const ActiveUser = memo(({ title, selected, data, onChange }) => {
  return (
    <div className={styles['active-user']} onClick={() => onChange && onChange(data) }>
      <div className={`${styles['active-user_title']} ${selected ? styles['active-user_title-active'] : ''}`}>{title}</div>
      {
        selected && (
          <img className={styles['active-user_selected']} src={require('@/images/groupMessage/radio_hook.png')} />
        )
      }
    </div>
  )
})

ActiveUsers.type = '活跃用户'

const AgentAreas = React.forwardRef((props, ref) => {
  const [value, setValue] = useState()
  const areas = useSelector(({ groupMessage }) => groupMessage.areas)
  const dispatch = useDispatch()

  useEffect(() => {
    if(areas.length === 0) {
      dispatch({ type: 'groupMessage/getAreas' })
    }
  }, [])

  useImperativeHandle(ref, () => ({
    getValue: () => ({
      value: [value],
      title: areas.find(({ id }) => id === value).name
    })
  }))

  return (
    <div className={styles['agent-areas']}>
      {
        areas.map(({ id, name }) => (
          <div key={id} className={`${styles['agent-area']} ${value === id ? styles['agent-area_active'] : ''}`} onClick={() => setValue(id)}>{name}</div>
        ))
      }
    </div>
  )
})

AgentAreas.type = '代理区域'

const FollowTypes = React.forwardRef((props, ref) => {
  const standards = useSelector(({ groupMessage }) => groupMessage.standards)
  const dispatch = useDispatch()
  useEffect(() => {
    if(standards.size === 0){
      dispatch({ type: 'groupMessage/getStandards' }).then(res => {
        if(res.code === 1000){
          setWordId(res.data[0].word_id)
        }
      })
    } else {
      setWordId([...standards][0][0])
    }
  }, [])

  const list = useMemo(() => [...standards.values()], [standards])

  const [wordId, setWordId] = useState()

  const treatment = useMemo(() => {
    const tr = standards.get(wordId)
    if(!wordId || !tr) return []

    if(!tr.treatment){
      dispatch(({ type: 'groupMessage/getTreatment', payload: wordId }))
      return []
    }

    return tr.treatment
  }, [wordId, standards])

  const treatmentRef = useRef()
  useImperativeHandle(ref, () => ({
    getValue: () => {
      const treatmentId = treatmentRef.current.value

      return {
        value: [wordId, treatmentId],
        title: `${
          list.find(({ word_id }) => word_id === wordId).name
        }-${
          treatment.find(({ attending_id }) => attending_id === treatmentId).name
        }`
      }
    }
  }))

  return (
    <div className={styles['follow-types']}>
      <div className={styles['follow-type_standards_wrapper']}>
        <div className={styles['follow-type_standards']}>
          {
            list.map(item => (
              <div 
                key={item.word_id}
                className={`${styles['follow-type_standard']} ${
                  wordId === item.word_id ? styles['follow-type_standard_active'] : ''
                }`}
                onClick={() => setWordId(item.word_id)}
              >{item.name}</div>
            ))
          }
        </div>
      </div>
      <div className={styles['follow-type_types']}>
        <FollowType ref={treatmentRef} title="主治功能" max={1} data={treatment} prop="attending_id" />
      </div>
    </div>
  )
})

const FollowType = selector()(({ title, data = [], prop, value, setValue }) => {
  return (
    <div className={styles['follow-type']}>
      <div className={styles['follow-type_title']}>{title}</div>
      <div>
        {
          data.map(item => (
            <div 
              key={item[prop]} 
              className={`${styles['follow-type_type']} ${value === item[prop] ? styles['follow-type_type_active'] : ''}`} 
              onClick={() => setValue(item[prop])}
            >{item.name}</div>
          ))
        }
      </div>
    </div>
  )
})

FollowTypes.type = '关注类型'