import request from '@/utils/request2'

/**
 * 获取字号
 */
export function getStandards(){
  return request({
    url: `/v2/product/word`,
    method: 'get'
  })
}

/**
 * 获取主治功能
 * @param {*} params
 */
export function getTreatment(params){
  return request({
    url: `/v2/product/attendingFunc`,
    method: 'get',
    params
  })
}


/**
 * 获取招商区域列表
 * @param {*} params 
 */
export function getAreas(params){
  return request({
    url: `/product/getArea`,
    method: 'get',
    params
  })
}

/**
 * 获取我的产品列表
 * @param {Object} params
 * @param {number} params.page - 页数
 * @param {number} params.limit - 条数
 * @param {number} params.status - 0 未发布 1 已发布
 * @returns {Promise}
 */
export function getOwnProducts(params) {
  return request({
    url: '/product/mine',
    method: 'get',
    params
  })
}