import React from 'react'
import { Mask } from '@/components_v2/Modal'
import styles from './index.module.scss'

function VipRefreshModal({ children, onDetermine }) {
  return (
    <Mask>
      <div className={styles.modal_refresh}>
        <div className={styles.modal_refresh_title}>发送成功</div>
        <div className={styles.modal_refresh_content}>{ children }</div>
        <img className={styles.modal_refresh_btn} onClick={onDetermine} src={require('@/images/groupMessage/button1.png')} />
      </div>
    </Mask>
  )
}

export default VipRefreshModal
