import React, { useCallback, useRef, useState } from 'react'
import styles from './index.module.scss'
import Header from '@/components_v2/Header'
import { goBack } from "@/utils/webViewUtils"
import SendWithTarget from '../components/SendWithTarget'
import { sendGroupMessage } from '@/services/message'
import { Toast } from 'antd-mobile'

function GroupMessageWithText() {
  const [visible, setVisible] = useState(false)
  const closeTarget = useCallback(() => setVisible(false), [])
  const [value, setValue] = useState('')
  const next = useCallback(() => {
    if(!value){
      Toast.info('请先填写发送内容', 2, null, false)
      return
    }

    setVisible(true)
  }, [value])

  const onChange = useCallback(({ target }) => setValue(target.value), [])

  const send = useCallback(({ type, value: val, title }) => ({
    promise: sendGroupMessage({
      type: 0,
      body: value,
      mass_type: type,
      mass_value: val,
      extra: title
    }),
    success: res => goBack()
  }), [value])

  return (
    <div className={styles.container}>
      <Header title="发送文字" onGoBack={goBack} />
      <div className={styles.title}>添加内容</div>
      <textarea className={styles.textarea} value={value} onChange={onChange} placeholder="请填写消息内容..." maxLength={100} />
      <div className={styles['next-step_wrapper']}>
        <button className={styles['next-step']} onClick={next}>下一步</button>
      </div>
      <SendWithTarget visible={visible} type="文字" onGoBack={closeTarget} onSend={send} />
    </div>
  )
}

export default GroupMessageWithText
