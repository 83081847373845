import React, { memo, useMemo } from 'react'
import styles from './index.module.scss'

function Header({ title, style, className, theme, onGoBack }) {
  
  const gobackImage = useMemo(() => {
    if(theme === 'white'){
      return require('@/images/icon/goback_white.png')
    }

    return require('@/images/icon/goback.png')
  }, [theme])

  const titleStyle = useMemo(() => {
    return { color: theme === 'white' ? 'white' : 'black' }
  }, [theme])

  return (
    <div style={style} className={`${styles.container} ${className ? className : ''}`}>
      <div className={styles.goback_wrapper} onClick={onGoBack}>
        <img src={gobackImage} alt="返回" className={styles.goback} />
      </div>
      <div style={titleStyle} className={styles.title}>{title}</div>
    </div>
  )
}

export default memo(Header)