export default {
  namespace: 'user',
  state: {
    token: ''
    // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjE2NzUxNywidHlwZSI6MCwiZXhwIjoxODc4MjAwNTk3LCJ0aW1lIjoxNjE5MDAwNTk3fQ.n4BS4u9dqdCEvfpU1w0KbjiLE8rfyMxAUCjm1lXhpnA'
  },
  reducers: {
    setToken(state, { payload: token }) {
      return { ...state, token }
    }
  },
  effects: {}
}