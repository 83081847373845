import { useMemo } from "react";
import { useLocation } from "@/router/dvaRouter";

export default function useQuery(params) {
  const query = useLocation()
  return useMemo(() => {
    let { search } = query
    if(!search) return {}
    
    search = search.slice(1).split('&')
    return search.reduce((prev, curr) => {
      let q = curr.split('=')
      prev[q[0]] = q[1]
      return prev
    }, {})
  }, [query])
}