import { history } from '@/app'
import { getChatroomId } from '@/services/message'

/**
 * 获取当前环境
 */
export function getEnvironment() {
  if (window.ReactNativeWebView) {
    return 'ReactNative'
  } else if (window.__wxjs_environment === "miniprogram") {
    return 'MiniProgram'
  } else {
    return 'Unknow'
  }
}

export const environment = getEnvironment()

/**
 * 向webView发送信息
 * @param {object} data 
 * @param {string} type
 */
export function postMessage(data) {
  if (environment === 'ReactNative') {
    window.ReactNativeWebView.postMessage(JSON.stringify(data))
  } else if (environment === 'MiniProgram') {
    window.wx.miniProgram.postMessage({ data })
  }
}

/**
 * 返回上一级
 */
export function goBack() {
  if (environment === 'ReactNative') {
    router({ mode: 'goBack' })
  } else if (environment === 'MiniProgram') {
    if (history.length > 2)
      history.goBack()
    else
      window.wx.miniProgram.navigateBack()
  } else {
    history.goBack()
  }
}

/**
 * 关闭webview
 */
export function close() {
  postMessage({ type: 'close' })
}

/**
 * 产品编辑
 * @param {*} pid - 产品id
 */
export async function productEdit(pid) {
  if (environment === 'ReactNative') {
    router({ route: 'product/release', params: { pid } })
  }
}

/**
 * 打开聊天室
 * @param {*} uid - 用户id
 */
export async function contactUser(uid) {
  if (environment === 'ReactNative') {
    let { code, data: { room_id }, msg } = await getChatroomId({ room_type: 0, participant: [uid] })
    if (code !== 1000) {
      return Promise.reject(msg)
    }
    router({ route: 'msgDetail', params: { room_id } })
  }

  if (environment === 'MiniProgram') {
    let { code, data: { room_id }, msg } = await getChatroomId({ room_type: 0, participant: [uid] })
    if (code !== 1000) {
      return Promise.reject(msg)
    }
    window.wx.miniProgram.navigateTo({ url: `/pages/message/chatRoom/index?roomID=${room_id}` })

  }

}

/** 只于 react native 相关 */

// function usePostMessage(callback, deps) {
//   // const unlistener = useRef().current
//   // const fn = useCallback(callback, deps)
// }

export function router(payload) {
  postMessage({ type: 'router', payload })
}

export function share(payload) {
  postMessage({ type: 'share', payload })
}

export function callPhone(phone) {
  if (environment === 'MiniProgram') {
    postMessage({ type: 'phoneCall', payload: { phoneNum: phone } })
    goBack()
  } else
    postMessage({ type: 'callPhone', payload: phone })
}

// const listenersMap = new Map()

// function addListener(key, cb) {
//   if(environment === 'ReactNative' && key && cb && typeof cb === 'function'){
//     let listeners = listenersMap.get(key) || new Set()
//     listeners.add(cb)
//     listenersMap.set(key, listeners)

//     return () => {
//       let listeners = listenersMap.get(key)
//       if(listeners){
//         listeners.delete(cb)
//       }
//     }
//   } else {
//     return () => {}
//   }
// }

// if(environment === 'ReactNative'){
//   window.addEventListener('message', function(msg) {
//     let data = msg.data
//     try {
//       data = JSON.parse(data)
//     } catch(err) {
//       console.warn('React Native onMessage Data', err);
//     }

//     const { type, ...restData } = data
//     const listeners = listenersMap.get(type)
//     if(!listeners || listeners.size === 0) {
//       return
//     }

//     listeners.forEach(listener => {
//       try {
//         listener(restData)
//       } catch(err) {
//         console.warn('React Native onMessage Callback', err)
//       }
//     })

//     listeners.clear()
//     listenersMap.delete(type)
//   })
// }