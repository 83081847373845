import { useCallback, useRef } from "react"
import { parseHashofURL } from "@/utils/util"
import app from '@/app'

export default function useDvaTokenByURL(prop = 't') {
  const { dispatch, getState } = app._store
  const token = useRef(getState().user.token).current
  if(!token) {
    const params = parseHashofURL(window.location.hash)
    if(params[prop]) {
      dispatch({ type: 'user/setToken', payload: params[prop] })
    }
  }

  return token
}