// 判断对象中值相等
function isObjectValueEqual(obj1, obj2) {
  // 方法返回一个由指定对象的所有自身属性的属性名
  //（包括不可枚举属性但不包括Symbol值作为名称的属性）组成的数组。
  var aProps = Object.getOwnPropertyNames(obj1);
  var bProps = Object.getOwnPropertyNames(obj2);
  if (aProps.length !== bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (obj1[propName] !== obj2[propName]) {
      return false;
    }
  }
  return true;
}
// 数组中删除指定对象
export const removeObj = (arr, obj) => {
  console.log("remove")
  var length = arr.length
  for (var i = 0; i < length; i++) {
    // 判断两个对象是否相等
    if (isObjectValueEqual(arr[i],obj)) {
      if (i === 0) {
        arr.shift()
        return
      } else if (i === length - 1) {
        arr.pop()
        return
      } else {
        arr.splice(i, 1)
        return
      }
    }
  }
}

// 取数组对象的id值 返回string
export const getArrKey = arr => {
  var newArr = []
  for (var i = 0, len = arr.length; i < len; i++) {
    newArr.push(arr[i].id)
  }
  return newArr.toString()
}

//查询数组中的值
export const indexBy = (array = [], key = "") => {
  var arr = []
  array.forEach(item => {
    if (item[key]) {
      arr.push(item[key])
    }
  })
  return arr
}

//判断是否为空(为空 true)
export const isEmpty = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  )
}

// 存储为键值对
export const keyValue = (key, value) => {
  var obj = {}
  obj[key] = value
  return obj
}

// 取数组对象的值
export const getArrValue = arr => {
  var len = arr.length
  var newArr = []
  for (var i = 0; i < len; i++) {
    newArr.push(Object.values(arr[i]))
  }
  return newArr
}
// 去掉开头和结尾的什么
export const myTrim = (str, reg) => {
  return str.replace(/(^reg)|(reg$)/g, "")
}
// 节流
export const lowerRequest = (func, delay) => {
  let time
  return function(...args) {
    if (time) {
      clearTimeout(time)
    }
    time = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
// 防止疯狂点击请求
export const throttle = (handle, wait) =>{
  let lastTime = 0;
  return function(){
    let nowTime = +new Date();
    if(nowTime - lastTime > wait){
      handle.apply(this, arguments);
      lastTime = nowTime;
    }
  }
}
// 生成 uuid
export const createUuid = () => {
  var s = []
  var hexDigits = "0123456789abcdef"
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = "4" // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-"

  var uuid = s.join("")
  return uuid
}

export const  baseUrl = "https://api.yaoxiebang.com.cn"

export const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const getOperationSystem = () => {
  var ua = navigator.userAgent.toLowerCase()
  if (/android/i.test(ua)) {
    return 'Android'
  }
  if (/adr/i.test(ua)) {
    return 'Android'
  }
  if (/iphone|ipad/i.test(ua)) {
    return 'IOS'
  }
  return 'other'
}

// 将浮点数点左边的数每三位添加一个逗号
// export const formatNumber = value => {
//   var re = /^(\\-?)(\d{1,3}(,\d\d\d)*(\.\d+)?|\d+(\.\d+)?)$/
//   if (!re.test(value)) {
//     return value
//   }
//   value += ""
//   const list = value.split(".")
//   const prefix = list[0].charAt(0) === "-" ? "-" : ""
//   let num = prefix ? list[0].slice(1) : list[0]
//   let result = ""
//   while (num.length > 3) {
//     result = `,${num.slice(-3)}${result}`
//     num = num.slice(0, num.length - 3)
//   }
//   if (num) {
//     result = num + result
//   }
//   return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`
// }

// 获取地址栏上携带的参数，如http://www.baidu.com?test=shuju; 得到shuju
// export const getUrlParam = name => {
//   if (window.location.href.indexOf("?") >= 0) {
//     var a = window.location.href
//       .slice(window.location.href.indexOf("?") + 1)
//       .split("&")
//     var t
//     var i = 0
//     var l = a.length
//     for (; i < l; i++) {
//       if ((t = a[i].split("="))[0] === name) return t[1]
//     }
//   }
//   return null
// }

//Date有需要参数可以传入 (Format(Date,"yyyy-MM-dd"))
// export const Format = (now, mask) => {
//   var d = now
//   var zeroize = function(value, length) {
//     if (!length) length = 2
//     value = String(value)
//     for (var i = 0, zeros = ""; i < length - value.length; i++) {
//       zeros += "0"
//     }
//     return zeros + value
//   }
//
//   return mask.replace(
//     /"[^"]*"|'[^']*'|\b(?:d{1,4}|m{1,4}|yy(?:yy)?|([hHMstT])\1?|[lLZ])\b/g,
//     function($0) {
//       switch ($0) {
//         case "d":
//           return d.getDate()
//         case "dd":
//           return zeroize(d.getDate())
//         case "ddd":
//           return ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"][d.getDay()]
//         case "dddd":
//           return [
//             "Sunday",
//             "Monday",
//             "Tuesday",
//             "Wednesday",
//             "Thursday",
//             "Friday",
//             "Saturday"
//           ][d.getDay()]
//         case "M":
//           return d.getMonth() + 1
//         case "MM":
//           return zeroize(d.getMonth() + 1)
//         case "MMM":
//           return [
//             "Jan",
//             "Feb",
//             "Mar",
//             "Apr",
//             "May",
//             "Jun",
//             "Jul",
//             "Aug",
//             "Sep",
//             "Oct",
//             "Nov",
//             "Dec"
//           ][d.getMonth()]
//         case "MMMM":
//           return [
//             "January",
//             "February",
//             "March",
//             "April",
//             "May",
//             "June",
//             "July",
//             "August",
//             "September",
//             "October",
//             "November",
//             "December"
//           ][d.getMonth()]
//         case "yy":
//           return String(d.getFullYear()).substr(2)
//         case "yyyy":
//           return d.getFullYear()
//         case "h":
//           return d.getHours() % 12 || 12
//         case "hh":
//           return zeroize(d.getHours() % 12 || 12)
//         case "H":
//           return d.getHours()
//         case "HH":
//           return zeroize(d.getHours())
//         case "m":
//           return d.getMinutes()
//         case "mm":
//           return zeroize(d.getMinutes())
//         case "s":
//           return d.getSeconds()
//         case "ss":
//           return zeroize(d.getSeconds())
//         case "l":
//           return zeroize(d.getMilliseconds(), 3)
//         case "L":
//           var m = d.getMilliseconds()
//           if (m > 99) m = Math.round(m / 10)
//           return zeroize(m)
//         case "tt":
//           return d.getHours() < 12 ? "am" : "pm"
//         case "TT":
//           return d.getHours() < 12 ? "AM" : "PM"
//         case "Z":
//           return d.toUTCString().match(/[A-Z]+$/)
//         // Return quoted strings with the surrounding quotes removed
//         default:
//           return $0.substr(1, $0.length - 2)
//       }
//     }
//   )
// }

// 校验数字
// export const isNumber = val => {
//   var regPos = /^\d+(\.\d+)?$/ //非负浮点数
//   var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ //负浮点数
//   if (regPos.test(val) || regNeg.test(val)) {
//     return true
//   } else {
//     return false
//   }
// }

//校验正负正数就返回true
// export const isIntNum = value => {
//   var regPos = /^\d+$/ // 非负整数
//   var regNeg = /^\\-[1-9][0-9]*$/ // 负整数
//   if (regPos.test(value) || regNeg.test(value)) {
//     return true
//   } else {
//     return false
//   }
// }

// 前拷贝
// export const simpleClone = initaObj =>{
//   var obj = {};
//   for ( let i in initaObj) {
//     obj[i] = initaObj[i];
//   }
//   return obj;
// }

// 深拷贝
// export const deepClone = (initalObj, finalObj) =>{
//   var obj = finalObj || {};
//   for (let i in initalObj) {
//    // 避免相互引用对象导致死循环，如initalObj.a = initalObj的情况
//     var prop = initalObj[i];
//     if(prop === obj) {
//       continue;
//     }
//     if (typeof prop === 'object') {
//       obj[i] = (prop.constructor === Array) ? [] : {};
//       deepClone(prop, obj[i]);
//     } else {
//       obj[i] = prop;
//     }
//   }
//   return obj;
// }

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * 解析url中的hash
 * @param {*} hash 
 */
export function parseHashofURL(hash) {
  if(!hash || typeof hash !== 'string'){
    return {}
  }

  let params = /^#\/.*\?(.*)$/.exec(hash)
  if(!params || !params[1]) {
    return {}
  }

  let obj = {}
  params[1].split('&').forEach(param => {
    param = param.split('=', 2)
    if(param.length >= 2){
      obj[param[0]] = param[1]
    }
  })

  return obj
}